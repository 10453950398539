export default {
  id: null,
  start_date: null,
  end_date: null,
  startsAt: null,
  endsAt: null,
  text: null,
  customer: {
    id: null,
    email: null,
    lastName: null,
    name: null,
    phone: null,
  },
  services: [{
    id: null,
    name: null,
    category: {
      id: null,
      name: null,
    },
    price: null,
    description: null,
    duration: null,
  }],
  payment: [],
  amount: null,
  note: null,
}
