<template>
  <div class="vselect vue-treeselect vue-treeselect--single vue-treeselect--searchable vue-treeselect--open-below vue-treeselect--branch-nodes-disabled">
    <div class="value-container">
      <div class="control has-icon has-icon-right input-container ">
        <validation-provider
          :name="label"
          :rules="rules"
        >
          <b-form-group
            slot-scope="{ valid, errors }"
            :label-for="inputId"
          >
            <b-form-input
              :id="inputId"
              ref="selected"
              v-model="mutableValue"
              type="text"
              class="form-control"
              :state="errors[0] ? false : (valid ? true : null)"
              @keyup="filterSelect($event)"
              @focus="onFocus"
              @keydown.up.prevent="onKeyUp"
              @blur="onBlur"
              @keydown.down.prevent="onKeyDown"
              @keydown.enter.prevent="onKeyEnter"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </div>
    </div>
    <b-list-group
      v-show="toggled"
      v-if="options"
      ref="dropdown"
      class="my-dropdown"
    >
      <b-list-group-item
        v-for="(item,index) in filterList"
        :key="index"
        :class="{active:index === pointer}"
        @mousedown="handleItemClick(item)"
        @mouseover="pointer = index"
      >{{ item[column] }}</b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BFormInput, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
  },
  // eslint-disable-next-line
  props: ['options', 'label', 'value', 'column', 'defaultState', 'inputId', 'rules'],
  data() {
    return {
      selected: null,
      toggled: false,
      filterList: [],
      mutableValue: null,
      defaultObject: {
        ...this.defaultState,
      },
      mutableOptions: [],
      pointer: 0,
    }
  },
  watch: {
    value(val) {
      this.mutableValue = val[this.column]
    },
    options(val) {
      this.filterList = val
      this.mutableOptions = val
    },
    pointer() {
      this.maybeAdjustScroll()
    },
  },
  mounted() {
    this.filterList = this.options
  },
  methods: {
    filterSelect(key) {
      if (!this.toggled) this.toggled = !this.toggled
      let oldArr = this.options
      if (this.mutableValue && this.mutableValue.length <= 0) {
        this.filterList = this.mutableOptions
      } else if (key.key.length === 1 || key.key === 'Backspace') {
        oldArr = oldArr.filter(item => {
          if (item[this.column].toLowerCase().includes(this.mutableValue.toLowerCase())) return true
          return false
        })
        this.filterList = oldArr
        // console.log('type', this.filterList)
      }
      // if (key.key == 'Enter')
      //     this.toggled = !this.toggled;
      this.defaultObject[this.column] = this.mutableValue
      this.$emit('input', this.defaultObject)
    },
    handleItemClick(item) {
      this.mutableValue = item[this.column]
      this.$emit('input', item)
      this.toggled = !this.toggled
    },
    onFocus() {
      this.$refs.dropdown.scrollTop = 0
      this.toggled = !this.toggled
    },
    onBlur() {
      this.toggled = false
    },
    onKeyUp() {
      if (this.pointer > 0) this.pointer -= 1
      if (this.maybeAdjustScroll) {
        this.maybeAdjustScroll()
      }
    },
    onKeyDown() {
      if (this.pointer < this.options.length && this.filterList.length) this.pointer += 1
      if (this.pointer === this.options.length) this.pointer = 0
      if (this.maybeAdjustScroll) {
        this.maybeAdjustScroll()
      }
    },
    onKeyEnter() {
      if (this.filterList.length > 0) this.handleItemClick(this.filterList[this.pointer])
      this.$refs.selected.blur()
      // this.$emit('input', this.mutableObject);
      this.toggled = false
    },
    maybeAdjustScroll() {
      const pixelsToPointerTop = this.pixelsToPointerTop()
      const pixelsToPointerBottom = this.pixelsToPointerBottom()
      // console.log(pixelsToPointerTop,pixelsToPointerBottom);
      if (pixelsToPointerTop <= this.viewport().top) {
        return this.scrollTo(pixelsToPointerTop)
      } if (pixelsToPointerBottom >= this.viewport().bottom) {
        return this.scrollTo(this.viewport().top + this.pointerHeight())
      }
      return 0
    },

    pixelsToPointerTop() {
      let pixelsToPointerTop = 0
      if (this.$refs.dropdown && this.$refs.dropdown.children) {
        for (let i = 0; i < this.pointer; i += 1) {
          pixelsToPointerTop += this.$refs.dropdown.children[i].offsetHeight
        }
      }
      return pixelsToPointerTop
    },

    pixelsToPointerBottom() {
      return this.pixelsToPointerTop() + this.pointerHeight()
    },

    pointerHeight() {
      const element = this.$refs.dropdown ? this.$refs.dropdown.children[this.pointer] : false
      return element ? element.offsetHeight : 0
    },

    viewport() {
      return {
        top: this.$refs.dropdown ? this.$refs.dropdown.scrollTop : 0,
        bottom: this.$refs.dropdown ? this.$refs.dropdown.offsetHeight + this.$refs.dropdown.scrollTop : 0,
      }
    },
    scrollTo(position) {
      const ref = this.$refs.dropdown ? this.$refs.dropdown.scrollTop = position : null
      return ref
    },
  },
}
</script>

<style lang="scss" scoped>
.vselect {
  display: block;
  position: relative;
}

.input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: content-box;
  box-shadow: none;
  background: none transparent;
  line-height: 1;
  vertical-align: middle
}

.value-container {
  position: relative;
  width: 100%;
  vertical-align: middle;
}

.my-dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 0.1rem;
  border-radius: 3px;
  transition: all 0.5s;
  z-index: 1;
  max-height: 10rem;
  overflow: auto
}

.active {
  cursor: pointer;
}

.form-group {
  margin-bottom: 0px;
}

.control.has-icon has-icon-right {
  margin-bottom: 0px;
}

.form-group>p {
  margin-bottom: 0px;
}
</style>
