<template>
  <div>
    <b-modal
      id="modal-calendar-appointment"
      v-model="appointmentModal.show"
      :title="title"
      no-close-on-backdrop
      modal-class="modal-primary"
      centered
      size="lg"
      @cancel="hideAppointmentModal"
    >
      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          id="create-appointment-form"
          slot-scope="{ validate }"
          class="p-2"
          @submit.prevent="submitAppointmentForm(validate())"
        >
          <b-row>
            <b-col
              md="4"
              xl="4"
              sm="12"
            >
              <!-- Date -->
              <validation-provider
                name="Date"
              >
                <b-form-group
                  label="Date"
                  label-for="date"
                >
                  <flat-pickr
                    v-model="appointmentModal.details.start_date"
                    class="form-control cursor-pointer"
                    :config="{ enableTime: true, altInput: true, altFormat: 'M d, Y h:iK', minuteIncrement: 1, locale: { firstDayOfWeek: 1 } }"
                  />
                  <b-form-invalid-feedback />
                </b-form-group>
              </validation-provider>
              <!-- Service name -->
              <label
                for="service-select"
                class="bs-title-form"
              >Service name</label>
              <button
                v-if="showSelectService && selectedServices.length >= 1"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeSelectService()"
              >×</button>
              <button
                v-else-if="!showSelectService && selectedServices.length >= 1"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="addService()"
              >
                <span aria-hidden="true">+</span>
              </button>
              <div
                v-if="showSelectService || selectedServices.length === 0"
                class="mt-1"
              >
                <!-- Service -->
                <validation-provider
                  name="Service"
                  rules="required"
                >
                  <b-overlay
                    :show="services.isPending"
                    rounded="sm"
                    variant="transparent"
                    spinner-variant="primary"
                    opacity="0.4"
                  >
                    <v-select
                      id="service-select"
                      v-model="selectService"
                      :options="servicesOptions"
                      :column="'name'"
                      :class="'mb-1'"
                      :label="'Service'"
                      :input-id="'service-select'"
                      :rules="'required'"
                      placeholder="Select service"
                      :default-state="selectServiceDefaultState"
                    />
                  </b-overlay>
                </validation-provider>
              </div>
              <div
                v-if="selectedServices"
                class="mt-1"
              >
                <div
                  v-for="(service, index) in selectedServices"
                  :key="index"
                >
                  <b-input-group class="mb-1">
                    <b-form-input
                      v-model="service.name"
                      trim
                      readonly
                    />
                    <b-input-group-append>
                      <button
                        type="button"
                        class="close border-radius"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="removeService(service)"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <b-row
                  md="12"
                  xl="12"
                  sm="12"
                >
                  <b-col
                    inline
                    md="6"
                    xl="6"
                    sm="12"
                    mb="0"
                  >
                    <!-- Price -->
                    <validation-provider
                      #default="{ valid, errors }"
                      name="Price"
                      rules="required|regex:^(([1-9])*([0])*)(\.(\d{1})(\d{1})?)?$"
                    >
                      <b-form-group
                        label="Price ($)"
                        label-for="price"
                      >
                        <b-form-input
                          id="price"
                          v-model.number="appointmentPrice"
                          trim
                          :state="errors[0] ? false : null"
                          placeholder="0.00$"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    xl="6"
                    sm="12"
                  >
                    <!-- Duration -->
                    <validation-provider
                      #default="{ valid, errors }"
                      name="Duration"
                      rules="required|integer"
                    >
                      <b-form-group
                        label="Duration (m)"
                        label-for="duration"
                      >
                        <b-form-input
                          id="duration"
                          v-model.number="appointmentDuration"
                          trim
                          :state="errors[0] ? false : null"
                          placeholder="min"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <!-- note -->
            <b-col
              md="4"
              xl="4"
              sm="12"
            >
              <b-form-group
                label="Note"
                label-for="note"
              >
                <b-form-textarea
                  id="note"
                  v-model="appointmentModal.details.note"
                  rows="4"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <!--/ bio -->
            <b-col
              md="4"
              xl="4"
              sm="12"
            >
              <!-- Customer Name -->
              <div
                v-if="!selectedCustomer.id"
              >
                <label
                  for="customer-name"
                  class="bs-title-form"
                >Customer name</label>
                <b-overlay
                  :show="services.isPending"
                  rounded="sm"
                  variant="transparent"
                  spinner-variant="primary"
                  opacity="0.4"
                >
                  <v-select
                    id="customer-name"
                    v-model="selectedCustomer"
                    :options="customersOptions"
                    :column="'customerFullName'"
                    :class="'mb-1'"
                    :label="'Customer'"
                    :input-id="'customer-select'"
                    :rules="'required'"
                    :default-state="selectedCustomer"
                    :autocomplete="'off'"
                  />
                </b-overlay>
                <div class="d-flex justify-content-end">
                  <b-button
                    v-b-modal.create-customer
                    variant="primary"
                  >
                    add new
                  </b-button>
                </div>
              </div>

              <div v-if="selectedCustomer.id">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="clearCustomerSelect"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <!-- Customer name -->
                <validation-provider
                  name="Name"
                >
                  <b-form-group
                    label="Customer name"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="selectedCustomer.name"
                      trim
                      readonly
                    />

                    <b-form-invalid-feedback />
                  </b-form-group>
                </validation-provider>

                <!-- Last name -->
                <validation-provider
                  name="Last Name"
                >
                  <b-form-group
                    label="Last Name"
                    label-for="last-name"
                  >
                    <b-form-input
                      id="last-name"
                      v-model="selectedCustomer.lastName"
                      trim
                      readonly
                    />

                    <b-form-invalid-feedback />
                  </b-form-group>
                </validation-provider>

                <!-- Email -->
                <validation-provider
                  name="Email"
                  rules="email"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="selectedCustomer.email"
                      trim
                      readonly
                    />

                    <b-form-invalid-feedback />
                  </b-form-group>
                </validation-provider>

                <!-- Company -->
                <validation-provider
                  name="Phone"
                >
                  <b-form-group
                    label="Phone"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="selectedCustomer.phone"
                      trim
                      readonly
                    />
                    <b-form-invalid-feedback />
                  </b-form-group>
                </validation-provider>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-if="appointmentModal.details.id"
          v-b-modal.delete-appointment
          variant="danger"
        >
          Delete
        </b-button>
        <b-button
          class="close-btn"
          variant="secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          form="create-appointment-form"
        >
          Save
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="delete-appointment"
      centered
      size="sm"
      title="Delete appointment"
      @ok="deleteAppointment(appointmentModal.details)"
    >
      <p>Do you really want to delete the appointment?</p>
    </b-modal>
    <b-modal
      id="create-customer"
      title="Create customer"
      modal-class="modal-primary"
      centered
      ok-title="Save"
      @cancel="resetNewCustomerForm"
    >
      <b-overlay
        :show="newCustomerModal.isPending"
        rounded="sm"
        variant="transparent"
        spinner-variant="primary"
        opacity="0.4"
      >
        <validation-observer
          ref="refFormCustomerObserver"
        >
          <b-form
            id="create-customer-form"
            slot-scope="{ validate }"
            class="p-2"
            @submit.prevent="submitCreateNewCustomer(validate())"
          >
            <b-row class="customer-form">
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  name="Name"
                  rules="required"
                >
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label="Name"
                    label-for="new-customer-name"
                  >
                    <b-form-input
                      id="new-customer-name"
                      v-model="newCustomer.name"
                      :state="errors[0] ? false : (valid ? true : null)"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  name="Last name"
                >
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label="Last name"
                    label-for="new-customer-last-name"
                  >
                    <b-form-input
                      id="new-customer-last-name"
                      v-model="newCustomer.lastName"
                      :state="errors[0] ? false : (valid && newCustomer.lastName ? true : null)"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  name="Phone"
                  rules="requiredPhoneOrEmail:@new-customer-email|regex:^(\+?[0-9]+)$"
                  vid="new-customer-phone"
                >
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label="Phone"
                    label-for="new-customer-phone"
                  >
                    <b-form-input
                      id="new-customer-phone"
                      v-model="newCustomer.phone"
                      name="new-customer-phone"
                      :state="errors[0] ? false : (valid && newCustomer.phone ? true : null)"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  name="Email"
                  rules="requiredPhoneOrEmail:@new-customer-phone|email"
                  :bails="false"
                  vid="new-customer-email"
                >
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label="Email"
                    label-for="new-customer-email"
                  >
                    <b-form-input
                      id="new-customer-email"
                      v-model="newCustomer.email"
                      name="new-customer-email"
                      :state="errors[0] ? false : (valid && newCustomer.email ? true : null)"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="secondary"
          :disabled="newCustomerModal.isPending"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="newCustomerModal.isPending"
          type="submit"
          form="create-customer-form"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput, BFormInvalidFeedback, BForm, BRow, BCol, BFormTextarea, BOverlay,
  BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import { required, regex } from '@validations'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import VSelect from '@/components/VSelect.vue'

extend('requiredPhoneOrEmail', {
  params: ['target'],
  validate(value, { target }) {
    return !!(value || target)
  },
  computesRequired: true,
  message: 'Phone or email are required',
})

export default {
  name: 'StylistCalendarAppointmentModal',
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    BOverlay,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    VSelect,
  },
  data() {
    return {
      title: 'Create appointment',
      selectedCustomer: {
        id: null,
      },
      showSelectService: false,
      selectService: this.selectServiceDefaultState,
      selectServiceDefaultState: {
        id: null,
        name: null,
      },
      selectedServices: [],
      appointmentPrice: 0,
      appointmentDuration: 0,
      customersOptions: [
        { customerFullName: null },
      ],
      servicesOptions: [
        { name: null },
      ],
      newCustomer: {
        email: null,
        phone: null,
        lastName: null,
        name: null,
      },
      required,
      regex,
    }
  },
  computed: {
    ...mapState('StylistCalendarStoreModule', {
      appointmentModal: state => state.appointmentModal,
      customers: state => state.customers,
      services: state => state.services,
      newCustomerModal: state => state.newCustomer,
      stylistStore: state => state.stylist,
    }),
  },
  watch: {
    appointmentModal: {
      deep: true,
      immediate: true,
      handler(appointment) {
        if (appointment.show) {
          this.title = 'Create appointment'
          if (appointment.details.id) {
            this.title = 'Update appointment'
            this.selectedCustomer = { ...appointment.details.customer, id: appointment.details.customer.id, customerFullName: `${appointment.details.customer.name || ''} ${appointment.details.customer.lastName || ''}` }
            this.selectedServices = [...appointment.details.services]
            this.appointmentDuration = this.getDuration(appointment.details.startsAt, appointment.details.endsAt)
            this.appointmentPrice = this.appointmentModal.details.amount
            this.selectService = appointment.details.services.first
          }
        }
      },
    },
    customers: {
      deep: true,
      handler(customers) {
        if (customers.response) {
          this.customersOptions = customers.response.map(obj => ({ ...obj, customerFullName: `${obj.name || ''} ${obj.lastName || ''}` }))
        }
      },
    },
    services: {
      deep: true,
      handler(services) {
        if (services.response) {
          this.servicesOptions = services.response
        }
      },
    },
    newCustomerModal: {
      deep: true,
      handler(customer) {
        if (customer.response) {
          this.selectedCustomer = customer.response
        }
        if (!customer.isPending) {
          this.$bvModal.hide('create-customer')
          this.resetNewCustomerForm()
        }
      },
    },
    selectService: {
      deep: true,
      handler(newSelectService) {
        if (newSelectService && newSelectService.id) {
          this.selectedServices.push(newSelectService)
          this.appointmentPrice = +this.appointmentPrice + +newSelectService.price
          this.appointmentDuration = +this.appointmentDuration + +newSelectService.duration
          this.showSelectService = false
        }
      },
    },
    appointmentPrice: {
      handler(newPrice) {
        if (newPrice < 0) {
          this.appointmentPrice = 0
        }
      },
    },
    appointmentDuration: {
      handler(newDuration) {
        if (newDuration < 0) {
          this.appointmentDuration = 0
        }
      },
    },
  },
  mounted() {
    this.fetchCustomers()
    this.fetchServices()
  },
  methods: {
    ...mapActions('StylistCalendarStoreModule', [
      'fetchCustomers',
      'fetchServices',
      'saveAppointment',
      'updateAppointment',
      'deleteAppointment',
      'saveCustomer',
    ]),
    hideAppointmentModal() {
      this.$store.dispatch('StylistCalendarStoreModule/hideAppointmentModal')
    },
    clearCustomerSelect() {
      this.selectedCustomer = {
        id: null,
      }
    },
    removeService(serviceToDelete) {
      this.selectedServices = this.selectedServices.filter(service => service !== serviceToDelete)
      if (this.selectedServices.length > 0) {
        this.appointmentPrice -= +serviceToDelete.price
        this.appointmentDuration -= +serviceToDelete.duration
      } else {
        this.appointmentPrice = 0
        this.appointmentDuration = 0
        this.selectService = this.selectServiceDefaultState
      }
    },
    persistAppointment() {
      const startsAt = moment(this.appointmentModal.details.start_date)
      const endsAt = moment(this.appointmentModal.details.start_date)

      const appointment = {
        ...this.appointmentModal.details,
        services: this.prepareServices(this.selectedServices),
        customer: this.selectedCustomer.id,
        amount: `${this.appointmentPrice}`,
        startsAt: startsAt.format('yyyy-MM-DD HH:mm:ss'),
        endsAt: endsAt.add(this.appointmentDuration, 'minutes').format('yyyy-MM-DD HH:mm:ss'),
        payment: {},
      }

      delete appointment.end_date
      delete appointment.start_date

      if (!appointment.id) {
        this.saveAppointment(appointment)
      } else {
        this.updateAppointment(appointment)
      }
      this.$bvModal.hide('modal-calendar-appointment')
    },
    getDuration(startsAt, endsAt) {
      const start = moment(startsAt)
      const end = moment(endsAt)
      const duration = moment.duration(end.diff(start))
      return duration.asMinutes()
    },
    submitAppointmentForm(validator) {
      validator.then(success => {
        if (success && this.selectedCustomer.id && this.selectedServices.length > 0) {
          this.persistAppointment()
        }
      })
    },
    submitCreateNewCustomer(validator) {
      validator.then(success => {
        if (success) {
          this.saveCustomer(this.newCustomer)
        }
      })
    },
    resetNewCustomerForm() {
      this.newCustomer = {
        email: null,
        phone: null,
        lastName: null,
        name: null,
      }
    },
    addService() {
      this.selectService = this.selectServiceDefaultState
      this.showSelectService = true
    },
    closeSelectService() {
      this.selectService = this.selectServiceDefaultState
      this.showSelectService = false
    },
    prepareServices(services) {
      const preparedServices = []
      services.forEach(service => {
        preparedServices.push(service.id.toString())
      })
      return preparedServices
    },
  },
}
</script>

<style lang="scss">
@import "~dhtmlx-scheduler/codebase/dhtmlxscheduler_flat.css";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/apps/calendar.scss';

#modal-calendar-appointment .modal-header .close {
  margin-top: -28px;
  margin-left: 0;
  margin-right: -14px;
  margin-bottom: -6px;

  &, &:active {
    transform: none;
  }
}

.border-radius {
  border: 1px solid rgb(216, 214, 222) !important;
  border-radius: 0 0.357rem 0.357rem 0;
}

.cursor-pointer .input {
  opacity: 1 !important;
}
</style>
